<template>
  <div v-if="summary" class="view-container home">
    <div v-if="profile && getExpert.has(profile.email)">
      <div class="home-block chart card warning">
        <div>
          Вам назначен курс "ЭКСПЕРТ ДИАГНОСТИКИ И РАЗВИТИЯ ЭМОЦИОНАЛЬНОГО
          ИНТЕЛЛЕКТА". Перейдите по
          <a :href="simulatorsLink">ссылке</a>, чтобы начать прохождение
        </div>
      </div>
    </div>
    <div v-if="getRight('private_person')">
      <div class="home-block chart card warning">
        <div>
          Добро пожаловать на платформу EISKILLS!
          <p></p>
        </div>
      </div>
    </div>
    <div v-if="getRight('getWelcomeImg')">
      <div class="home-block chart card warning">
        <div>
          Добро пожаловать в личный кабинет HR/компании на платформе EISKILLS!
          <p></p>
        </div>
      </div>
    </div>

    <div v-if="getRight('getWelcomeImg')" class="display--flex justify-content-between welcomeImg">
      <img class="welcomeImg__item_mob" src="@/assets/images/for_LK_mobi.svg" />
      <img class="welcomeImg__item_mob" src="@/assets/images/for_LK_mob.svg" />
      <img class="welcomeImg__item" src="@/assets/images/for_LK(1).svg"/>
      <img class="welcomeImg__item" src="@/assets/images/for_LK(3).svg"/>
    </div>

    <div v-if="getRight('mainStatistic')" class="charts-block card"  style="display: none;">
      <div class="charts">
        <home-chart v-if="summary.test" :chartData="summary.test"/>
      </div>
      <div v-if="summary.test" class="home-block home-block__rows test-table">
        <home-table1 :tableData="summary.test" :users="summary.users"/>
      </div>
    </div>
    <div
        v-if="getRight('mainStatistic')"
        class="flex card table2 align-item-center"
    >
      <table2
          :tableData="summary.test"
          :users="summary.users"
          :groups="summary.groups.length"
      />
    </div>
    <div class="card justify-content-center trainers" v-if="summary.test.all.total > 0">
      <div class="home-block__trainers_image">
        <img src="@/assets/images/trainers.png"/>
      </div>
      <div class="home-block__title title">
        <p>Пройдено тестирований - {{ summary.test.all.total }}. Постройте на их основании индивидуальные траектории
          развития, чтобы получить назначение на тренажеры.</p>
        <button @click="showConsultModal" class="btn btn-accent display--block">Заказать</button>
      </div>
    </div>
    <pop-up-form title="Получить консультацию по продукту"
                 comment="Хочу индивидуальные траектории развития"
                 v-if="openConsultModal"
                 @closeModal="openConsultModal = false"/>

    <div class="flex justify-content-center card">
      <courses :courses="summary.courses"/>
    </div>
    <div class="flex home__big-row">
      <div>
        <test :test="summary.current_passing"/>
      </div>
      <div>
        <socials/>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "@vue/runtime-core";
import useCheckRights from "../../mixins/useCheckRights";
import reports from "../../mixins/dictionaries/reports";
import {useStore} from "vuex";
import HomeChart from "@/feature/home/chart";
import HomeTable1 from "@/feature/home/table1";
import Courses from "@/feature/home/courses";
import Socials from "@/feature/home/socials";
import Test from "@/feature/home/test";
import getExpertCourse from "../../mixins/getExpertCourse";
import Table2 from "../../feature/home/table2.vue";
import PassingTable from "../../feature/testing/tables/PassingTable.vue";
import PopUpForm from "@/components/Popups/PopUpForm";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Socials,
    Courses,
    HomeTable1,
    HomeChart,
    Test,
    Table2,
    PassingTable,
    PopUpForm,
  },
  name: "home",
  setup() {
    const store = useStore(),
        {getRight} = useCheckRights(),
        getExpert = getExpertCourse;
    const summary = computed(() => store.state.summary),
        profile = computed(() => store.state.profile.profile),
        simulatorsLink = computed(() => store.state.simulatorsLink),
        userTrajectoryCount = ref(0)

    onBeforeMount(() => {
      loadSummary();
    });
    const loadSummary = () => {
      store.commit("setIsGlobalLoad", true);
      store
          .dispatch("getSummary")
          .finally(() => store.commit("setIsGlobalLoad", false));
      if (profile.value && profile.value.id) {
        store.dispatch("users/getUserTrajectories", profile.value?.id)
            .then(() => userTrajectoryCount.value = computed(() => store.state.users.userTrajectories))
      }
    };

    watch(profile, () => {
      loadSummary();
    });

    const openConsultModal = ref(false)
    const showConsultModal = () => {
      openConsultModal.value = !openConsultModal.value
    }

    return {
      getRight,
      summary,
      reports,
      getExpert,
      profile,
      simulatorsLink,
      userTrajectoryCount,
      openConsultModal,
      showConsultModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  .card {
    flex-direction: row;
    padding-top: 0;
  }

  &__big-row {
    margin: 0 -10px 29px;

    & > div {
      padding: 0 10px;
      flex: 1 0 50%;
    }

    @media (max-width: 1130px) {
      flex-direction: column;
      margin-bottom: 0;
      & > div {
        flex-basis: 100%;
      }
    }
  }

  .home-block {
    min-width: 430px;
    padding: 2% 15px;

    &__rows {
      flex-direction: column;
    }

    @media (max-width: 640px) {
      min-width: auto;
    }
    @media (max-width: 1599px) {
      &__rows {
        width: 100%;
        border-top: 1px solid var(--cream-color);
      }

      .big-row:last-child {
        border-bottom: none;
      }
    }
    @media (max-width: 1130px) {
      width: 100%;
    }
  }
}

.trainers {
  align-items: center;
  justify-content: center;

  .title {
    width: 50%;
    font-size: 20px;

    button {
      margin-top: 40px;
    }
  }
}

.passings {
  flex-direction: column !important;
  padding: 2% 25px !important;

  .title {
    padding-top: 15px;
  }

  & > div {
    flex: 1;
  }
}

.charts-block {
  display: grid;
  grid-template: "charts" "test-table";

  .charts {
    grid-area: charts;
    display: flex;
    flex-direction: column;
  }

  .test-table {
    grid-area: test-table;
  }

  @media (min-width: 1165px) {
    grid-template: "charts charts" "test-table test-table";
    .charts {
      flex-direction: row;
    }
  }
  @media (min-width: 1600px) {
    grid-template: "charts charts test-table";
    .chart {
      border-right: 1px solid var(--cream-color);
    }
  }
}

@media (max-width: 1390px) {
  .table2 {
    flex-direction: column !important;
  }
}

.warning {
  font-size: 19px;
  border: 1px solid var(--main-color);

  a {
    display: inline-block;
    color: var(--main-color);
    font-weight: 500;
  }

  a:hover {
    text-decoration: underline;
  }
}

.welcomeImg {

  &__item {
    width: 49.4%;
    border-radius: 10px;
    margin: 10px 0;
    @media (max-width: 650px) {
      display: none;
    }
  }
  @media (max-width: 1164px) {
    flex-direction: column;
    &__item {
      width: 100%;
    }
  }
  &__item_mob {
    display: none;

    @media (max-width: 650px) {
      display: block;
      background-color: #ffffff;
      border-radius: 10px;
      margin: 10px 0;
      padding: 10px;
    }
  }
}


</style>
<style lang="scss">
.home-block {
  padding: 2% 15px;

  &__trainers_image {
    width: 49%;
    text-align: center;

    img {
      width: 100%;
      max-width: 420px;
    }
  }

  &__title {
    font-size: 18px;
    padding: 15px 0 15px 15px;
    margin: 0 10px 20px;
    border-bottom: 1px solid var(--cream-color);
  }


  @media (max-width: 1390px) {
    &__trainers_image {
      width: 100%;
    }
  }
}
</style>