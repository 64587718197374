<template>
  <div class="card home-block home-block__rows w-full h-full">

  </div>
</template>

<script>
export default {
  name: "socials",
};
</script>

<style lang="scss" scoped>
.social-block {
  height: 100%;

  &__social-icon {
    flex-basis: 50%;

    a {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;

      i {
        font-size: 75px;
        margin-bottom: 5px;
      }
    }
  }
}
</style>